import { Anchor, Container, SimpleGrid, Text, Title } from "@mantine/core";
import { Trans, useTranslation } from "react-i18next";
import { useFeaturesStyles } from "./Features.styles";
import FeatureCard from "./Card";
import { PiCallBellFill } from "react-icons/pi";
import { TbMassage, TbPhysotherapist, TbReport, TbHomeCog } from "react-icons/tb";
import { PiNewspaperClippingBold } from "react-icons/pi";
import { Feature } from "./Features.types";

const Features = () => {
  const { classes } = useFeaturesStyles();
  const { t } = useTranslation();

  const featuresData: Feature[] = [
    {
      title: t("features.featureOneTitle"),
      description: t("features.featureOneDescription"),
      icon: PiCallBellFill,
      color: "teal",
      size: 26,
    },
    {
      title: t("features.featureTwoTitle"),
      description: t("features.featureTwoDescription"),
      icon: TbMassage,
      color: "teal",
      size: 26,
    },
    {
      title: t("features.featureThreeTitle"),
      description: t("features.featureThreeDescription"),
      icon: TbReport,
      color: "teal",
      size: 26,
    },
    {
      title: t("features.featureFourTitle"),
      description: t("features.featureFourDescription"),
      icon: PiNewspaperClippingBold,
      color: "teal",
      size: 26,
    },
    // {
    //   title: t("features.featureFiveTitle"),
    //   description: t("features.featureFiveDescription"),
    //   icon: TbPhysotherapist,
    //   color: "teal",
    //   size: 26,
    // },
    {
      title: t("features.featureSixTitle"),
      description: (
        <Trans
          i18nKey="features.featureSixDescription"
          components={{
            CustomText: (
              <Anchor href="https://www.multisoft.ro/program/solon-spa-software-gestiune-baze-de-tratament" />
            ),
          }}
        />
      ),
      icon: TbHomeCog,
      color: "teal",
      size: 26,
    },
  ];

  const features = featuresData.map((feature) => (
    <FeatureCard key={feature.title} feature={feature} />
  ));

  const SimpleGridProps = {
    cols: 3,
    spacing: "xl",
    mt: 50,
    breakpoints: [
      { maxWidth: "md", cols: 2 },
      { maxWidth: "sm", cols: 1 },
    ],
  };

  return (
    <Container size="lg" py="xl" mt={30} className={classes.container}>
      <Title order={1} className={classes.title} ta="center" mt="sm">
        <Trans
          i18nKey="landing.title"
          components={{ CustomText: <Text span c="#12B886" inherit /> }}
        />
      </Title>
      <Text c="dimmed" className={classes.description} ta="center" mt="md">
        {t("landing.description")}
      </Text>
      <SimpleGrid {...SimpleGridProps}>{features}</SimpleGrid>
    </Container>
  );
};

export default Features;
