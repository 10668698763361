import { createStyles } from "@mantine/core";

export const useFeaturesStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 24,
    },
  },

  description: {
    maxWidth: 600,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: "#12B886",
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  container: {
    padding: "1.5rem 1rem 1.5rem 1rem",
    [theme.fn.smallerThan("md")]: {
      padding: "1.5rem 0rem 1.5rem 0rem",
    },
  },
}));
