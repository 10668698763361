import { Flex, Paper, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useContactStyles } from "../Contact.styles";
import Option from "./Option";
import { SiMaildotru } from "react-icons/si";
import { FiPhone } from "react-icons/fi";
import { GrLocation } from "react-icons/gr";

const Information = () => {
  const { t } = useTranslation();
  const { classes } = useContactStyles();

  const options = [
    {
      icon: GrLocation,
      label: t("contact.location"),
      value: "Str. Aleea Albastrelelor nr. 4, Constanta",
      size: 26,
    },
    {
      icon: SiMaildotru,
      label: "Email",
      value: "office@multisoft.ro",
      href: "mailto:office@multisoft.ro",
      size: 23,
    },
    {
      icon: FiPhone,
      label: t("contact.phone"),
      value: "+40 730 628 080",
      href: "tel:+40730628080",
      size: 23,
    },
  ];

  const contactOptions = options.map((contactOption) => (
    <Option key={contactOption.label} contactOption={contactOption} />
  ));

  return (
    <>
      <Paper p="xl" radius="md" withBorder shadow="md">
        <Title order={4} fz="lg" fw={700} mb="lg" className={classes.title}>
          {t("contact.info")}
        </Title>
        <Flex direction="column" gap="lg">
          {contactOptions}
        </Flex>
      </Paper>
    </>
  );
};

export default Information;
