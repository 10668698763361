import { Header, Flex, Text } from "@mantine/core";
import { useHeaderStyles } from "./Header.styles";
import { Logo } from "../../../assets";
import LanguagePicker from "./LanguagePicker";
import { useHeadroom } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";

const HeaderComponent = () => {
  const pinned = useHeadroom({ fixedAt: 60 });
  const { classes } = useHeaderStyles(pinned);
  const navigate = useNavigate();

  return (
    <Header className={classes.header} height={60} fixed={true}>
      <Flex h="100%" align="center" justify="space-between" className={classes.content}>
        <Flex
          direction="column"
          align="flex-end"
          className={classes.logo}
          onClick={() => navigate("/")}
          mt={-9}
        >
          <img alt="brand" src={Logo} />
          <Text mr="xs" mt="-13px" className={classes.brandText} fw={500}>
            We propose more
          </Text>
        </Flex>

        <LanguagePicker />
      </Flex>
    </Header>
  );
};

export default HeaderComponent;
