import { Anchor, Flex, Text } from "@mantine/core";
import { IconType } from "react-icons";
import { useContactStyles } from "../Contact.styles";

interface OptionProps {
  contactOption: {
    icon: IconType;
    label: string;
    value: string;
    href?: string;
    size: number;
  };
}

const Option = (props: OptionProps) => {
  const { classes } = useContactStyles();
  return (
    <Flex align="center" gap="sm">
      <props.contactOption.icon className={classes.optionIcon} size={props.contactOption.size} />
      <Flex direction="column">
        <Text fz="xs">{props.contactOption.label}</Text>
        <Flex gap="5px">
          {props.contactOption.href ? (
            <Anchor href={props.contactOption.href}>
              {props.contactOption.value}
            </Anchor>
          ) : (
            <Text fz="md">{props.contactOption.value}</Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Option;
