import { Contact, Features } from "../components";

const LandingPage = () => {
  return (
    <>
      <Features />
      <Contact />
    </>
  );
};

export default LandingPage;
