import { createStyles } from "@mantine/core";

export const useCardStyles = createStyles((theme, color: string) => ({
  card: {
    border: `${1}px solid ${theme.colors.gray[1]}`,
    backgroundColor: "rgba(255, 255, 255, 1)",
    transition: "all ease .5s",
    "&:hover": {
      border: `${1}px solid ${theme.colors[color][1]}`,
      backgroundColor: theme.colors[color][1],
    },
  },

  themeIcon: {
    boxShadow: `0 0 0 8px ${theme.colors[color][0]}`,
    color: theme.colors[color][2],
    transition: "all ease .5s",
  },

  themeIconHover: {
    boxShadow: `0 0 0 8px ${theme.colors[color][2]}`,
    color: theme.colors[color][0],
  },
}));
