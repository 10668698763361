import { Flex, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Information } from "./components";
import { useContactStyles } from "./Contact.styles";

const ContactForm = () => {
  const { t } = useTranslation();
  const { classes } = useContactStyles();

  return (
    <Flex direction="column" align="center" mt={120} pb={60}>
      <Title order={3}>
        {t("contact.title")}
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        {t("contact.description")}
      </Text>
      <Flex className={classes.wrapper} justify="center" mt="30px">
        <Information />
        <Flex style={{}}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2862.2209080855846!2d28.628684699999994!3d44.1613062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40bae54187f1c515%3A0x9899ae9df3c37922!2sAleea%20Alb%C4%83strelelor%204%2C%20Constan%C8%9Ba%20900132!5e0!3m2!1sro!2sro!4v1692258195593!5m2!1sro!2sro"
            className={classes.map}
            title="map"
            loading="lazy"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ContactForm;
