import { Outlet } from "react-router-dom";
import { AppShell } from "@mantine/core";
import { HeaderComponent, FooterComponent } from "./components";
import { useLayoutStyles } from "./Layout.styles";

const Layout = () => {
  const { classes } = useLayoutStyles();
  const AppShellProps = {
    fixed: false,
    classNames: { main: classes.main },
    header: <HeaderComponent />,
    footer: <FooterComponent />,
  };

  return (
    <AppShell {...AppShellProps}>
      <Outlet />
    </AppShell>
  );
};

export default Layout;
