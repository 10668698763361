import { Avatar, ActionIcon, Menu } from "@mantine/core";
import { useTranslation } from "react-i18next";

const LanguagePicker = () => {
  const { t, i18n } = useTranslation();

  return (
    <Menu shadow="md" withArrow>
      <Menu.Target>
        <ActionIcon size="lg" variant="subtle" color="dark">
          <Avatar
            src={`/icons/${i18n.language?.toString()}.png`}
            radius="xl"
            size={20}
          />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          onClick={() => {
            i18n.changeLanguage("ro");
            localStorage.setItem("language", "ro");
          }}
        >
          <Avatar src="/icons/ro.png" radius="xl" size="xs" />
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            i18n.changeLanguage("en");
            localStorage.setItem("language", "en");
          }}
        >
          <Avatar src="/icons/en.png" radius="xl" size="xs" />
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default LanguagePicker;
