import { Footer, Group, Text, Anchor, Flex } from "@mantine/core";
import { useFooterStyles } from "./Footer.styles";

const FooterComponent = () => {
  const { classes } = useFooterStyles();
  
  return (
    <Footer height={60} px="md">
      <Flex justify="space-between" align="center" h="100%">
        <Group></Group>
        <Text fw={600}>
          Powered by
          <Anchor href="https://www.multisoft.ro/" target="_blank" ml="4px">
            Multisoft
          </Anchor>
        </Text>
      </Flex>
    </Footer>
  );
};

export default FooterComponent;
