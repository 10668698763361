import { Card, Text, ThemeIcon, Title } from "@mantine/core";
import { useState } from "react";
import { useCardStyles } from "./Card.styles";
import { Feature } from "./Features.types";

interface FeatureCardProps {
  feature: Feature;
}

const FeatureCard = (props: FeatureCardProps) => {
  const { classes, theme, cx } = useCardStyles(props.feature.color);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const CardProps = {
    shadow: "md",
    radius: "md",
    className: classes.card,
    padding: "xl",
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  };

  return (
    <Card {...CardProps}>
      <ThemeIcon
        radius="xl"
        size={48}
        className={cx(classes.themeIcon, {
          [classes.themeIconHover]: isHovering === true,
        })}
        color={
          isHovering
            ? theme.colors[props.feature.color][0]
            : theme.colors[props.feature.color][2]
        }
      >
        <props.feature.icon
          size={props.feature.size}
          color={theme.colors[props.feature.color][9]}
        />
      </ThemeIcon>
      <Title order={3} fz="lg" fw={500} mt="md">
        {props.feature.title}
      </Title>

      <Text fz="sm" color="dimmed" mt="sm">
        {props.feature.description}
      </Text>
    </Card>
  );
};

export default FeatureCard;
